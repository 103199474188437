import * as React from "react";
import {
  Wrapper,
  EventWrapper,
  PdpWrapper,
  DescriptionWrapper,
  DownloaderWrapper,
  PhotosWrapper,
CarrouselWrapper2,
  TitleWrapper,
  ImageWrapper,
  MobileTile
} from "./NosFantomesV2.module.css";
import Carrousel from "../../../../components/Carrousel";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';

import Tile from "./../../../../res/Nos fantomes V2/fantomes-v2-tile.png"
import InSitu1 from "./../../../../res/Nos fantomes V2/insitu-1.jpg";
import InSitu2 from "./../../../../res/Nos fantomes V2/insitu-2.jpg";
import InSitu3 from "./../../../../res/Nos fantomes V2/insitu-3.jpg";
import InSitu4 from "./../../../../res/Nos fantomes V2/insitu-4.jpg";
import InSitu5 from "./../../../../res/Nos fantomes V2/insitu-5.jpg";

const isBrowser = typeof window !== "undefined";
const infos ={
  photos: [
    { src: InSitu2, name: "InSitu2" },
    { src: InSitu3, name: "InSitu3" },
    { src: InSitu5, name: "InSitu5" },
  ],
};

const NosFantomesV2 = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <div className={EventWrapper}>
        <img className={PdpWrapper} src={Tile} alt="Nos fantomes v2"/>
        <div className={DescriptionWrapper}>
        <h1 className={TitleWrapper}>NOS FANTÔMES V2<h2 style={{paddingLeft: "16px"}}></h2></h1>
        <br />
        <img className={MobileTile} src={Tile} alt="Nos fantomes v2"/>
        <br />
        <p>
          A l’occasion de la FIAC 2021, GHOST GALERIE, spécialiste du Post-graffiti, présente une V2 de son exposition inaugurale ‘’Nos fantômes‘’. Cet évènement réunira des oeuvres historiques et plus récentes d’une vingtaine d’artistes à travers une quarantaine d’oeuvres.Les fantômes sont partout ou peut-être nulle part. Ils reflètent nos peurs, nos fantasmes ou peut-être même notre imaginaire. Ils apparaissent ou disparaissent, tout comme les oeuvres exposées sur les 3 niveaux de la galerie. Étaient-elles présentes lors du premier accrochage ? Pas certain. Conversent-elles toujours avec leurs mêmes voisines ? Pas évident. Les créateurs de ces voisines étaient-ils déjà exposés ? A venir découvrir.
          </p>
          <br></br>
          {/* <a className={DownloaderWrapper} href={DazePdf}download rel="noopener noreferrer" target="_blank">
              Télécharger le communiqué de presse
          </a> */}
        </div>
      </div>
        
        <div className={PhotosWrapper}>
          <Title title={"Vues in situ"} />
          <div className={CarrouselWrapper2}>
          <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
          </div>
        </div>
        <br />
    </div>
  );
};

export default NosFantomesV2;
