// extracted by mini-css-extract-plugin
export var CarrouselWrapper2 = "NosFantomesV2-module--CarrouselWrapper2--xtdnS";
export var DescriptionWrapper = "NosFantomesV2-module--DescriptionWrapper--OV5TJ";
export var DownloaderWrapper = "NosFantomesV2-module--DownloaderWrapper--q1DaT";
export var EventWrapper = "NosFantomesV2-module--EventWrapper--9Agh2";
export var ImageWrapper = "NosFantomesV2-module--ImageWrapper--Wa16w";
export var ImagesWrapper = "NosFantomesV2-module--ImagesWrapper--Eh3Qk";
export var MobileTile = "NosFantomesV2-module--MobileTile--TbBRN";
export var PdpWrapper = "NosFantomesV2-module--PdpWrapper--BhmFn";
export var PhotosWrapper = "NosFantomesV2-module--PhotosWrapper--Mzeoq";
export var TitleWrapper = "NosFantomesV2-module--TitleWrapper--28WL7";
export var Wrapper = "NosFantomesV2-module--Wrapper--fA799";